<script setup lang="ts">
import { Dropdown } from 'bootstrap'

defineProps({
  title: {
    type: String,
    default: '',
  },
  level: {
    type: Number,
    default: 0,
  },
  minWidth: {
    type: Number,
    default: 220,
  },
})

const emit = defineEmits<{
  (e: 'onBack'): void
  (e: 'onShow'): void
  (e: 'onHide'): void
}>()

const dropdown = ref()
const dropdownInstance = () => new Dropdown(dropdown.value)

useEventListener(dropdown, 'show.bs.dropdown', () => emit('onShow'))
useEventListener(dropdown, 'hide.bs.dropdown', () => emit('onHide'))

const hide = () => (dropdownInstance()?.hide())

defineExpose({
  hide,
})
</script>

<template>
  <div class="dropdown h-100">
    <div
      ref="dropdown" data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
      class="h-100"
    >
      <slot />
    </div>
    <div class="dropdown-menu dropdown-menu-end bg-light p-1 my-2 ms-2">
      <div class="d-grid" :style="`width: min(${minWidth}px, 80vw);`" style="max-height: 80vh; grid-template-rows: min-content minmax(0, 1fr) min-content">
        <div class="hstack align-items-start gap-2 px-3 py-2">
          <button v-if="level > 0" class="btn btn-sm btn-link btn-link-muted p-0" @click="emit('onBack')">
            <i class="fas fa-chevron-left" />
          </button>
          <span class="fw-bold fs-4 me-auto text-break">{{ title }}</span>
          <slot name="header-actions" />
          <button class="btn btn-lg btn-link btn-link-muted p-0" @click="hide">
            <i class="fas fa-times" />
          </button>
        </div>
        <slot name="content" />
        <div class="px-3 py-2">
          <slot name="footer-actions" />
        </div>
      </div>
    </div>
  </div>
</template>
