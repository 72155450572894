import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useLanguageService } from '@/composables/language'
import { isUserLoggedIn } from '@/auth/utils'
import { publicPages } from '@/router/public-pages'

export function languageMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const languageService = useLanguageService()

  languageService.initializeLanguage()
  next()
}

export function authMiddleware(to: RouteLocationNormalized) {
  const authRequired = !publicPages.reduce(
    (a, b) => a || RegExp(b).test(<string>to.name),
    false,
  )

  if (authRequired && !isUserLoggedIn())
    return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
}
