import type { Ref } from 'vue'
import { useQuoteStore } from '@store/quote'
import type { AddressCheckResult } from '@/utils/address-validation'
import { areAddressesUnique, isDisallowedCountry } from '@/utils/address-validation'
import { TypeEnum } from '@apiTypes'
import type { QuoteByHash, QuoteByID, VehicleTypeEnum, Waypoint } from '@apiTypes'

export function useAddressValidator() {
  const result = ref<AddressCheckResult>({
    pickup: { errors: [], warnings: [] },
    delivery: { errors: [], warnings: [] },
    global: { errors: [], warnings: [] },
  })

  const { t } = useI18n()
  const quoteStore = useQuoteStore()

  const validateAddress = (address: Waypoint, type: TypeEnum) => {
    if (!address || !address?.country || !address.city) {
      result.value[type].errors.push(
        t('You need to specify { type } which has at least city and country.', type),
      )
    }

    if (isDisallowedCountry(address)) {
      const text = t('Looks like you would like to { loadType } goods in a country our system is not able to quote automatically. Don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.', { loadType: type === TypeEnum.Pickup ? t('load') : t('unload') })

      result.value[type].warnings.push(text)
    }
  }

  const validateUniqueness = (pickupAddress: Waypoint, deliveryAddress: Waypoint) => {
    if (!areAddressesUnique(pickupAddress, deliveryAddress))
      result.value.global.errors.push(t('Addresses cannot be the same.'))
  }

  const formatPostcodeValidationText = (type: TypeEnum, area: string) => {
    return t('Looks like you would like to { loadType } goods in a { area } our system is not able to quote automatically. Don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.',
      { loadType: type === TypeEnum.Pickup ? t('load') : t('unload'), area: area === 'country' ? t('country') : t('region') })
  }

  async function validatePostcodes(vehicleType: VehicleTypeEnum, pickupAddress: Waypoint, deliveryAddress: Waypoint) {
    try {
      await quoteStore.validateWaypoints(vehicleType, pickupAddress, deliveryAddress)
    }
    catch (error: any) {
      for (const item of Object.values(error.response.data)) {
        switch (item) {
          case 'pickup_country': {
            result.value.pickup.warnings.push(formatPostcodeValidationText(TypeEnum.Pickup, 'country'))
            break
          }
          case 'pickup_postcode': {
            result.value.pickup.warnings.push(formatPostcodeValidationText(TypeEnum.Pickup, 'region'))
            break
          }
          case 'delivery_country': {
            result.value.delivery.warnings.push(formatPostcodeValidationText(TypeEnum.Delivery, 'country'))
            break
          }
          case 'delivery_postcode': {
            result.value.delivery.warnings.push(formatPostcodeValidationText(TypeEnum.Delivery, 'region'))
            break
          }
        }
      }
    }
  }

  const validate = async (vehicleTypeRef: Ref<VehicleTypeEnum>, quoteRef: Ref<QuoteByID | QuoteByHash | Partial<QuoteByID>>) => {
    const quote = unref(quoteRef)
    const vehicleType = unref(vehicleTypeRef)

    const pickupAddress = quote.waypoints?.find(wp => wp.type === TypeEnum.Pickup)
    const deliveryAddress = quote.waypoints?.find(wp => wp.type === TypeEnum.Delivery)

    result.value = {
      pickup: { errors: [], warnings: [] },
      delivery: { errors: [], warnings: [] },
      global: { errors: [], warnings: [] },
    }

    validateAddress(pickupAddress, TypeEnum.Pickup)
    validateAddress(deliveryAddress, TypeEnum.Delivery)
    validateUniqueness(pickupAddress, deliveryAddress)

    await validatePostcodes(vehicleType, pickupAddress, deliveryAddress)

    return result
  }

  const hasIssues = computed(() => {
    return (
      result.value.pickup.errors.length > 0
      || result.value.pickup.warnings.length > 0
      || result.value.delivery.errors.length > 0
      || result.value.delivery.warnings.length > 0
      || result.value.global.errors.length > 0
      || result.value.global.warnings.length > 0
    )
  })

  return { validate, hasIssues }
}
