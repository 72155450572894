<script lang="ts" setup>
import { localeFlag, localeTitle } from '@/plugins/i18n'
import type { PreferredLanguageEnum } from '@apiTypes'

defineProps({
  freeQuote: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits<(e: 'change', locale: PreferredLanguageEnum) => void>()

const dropdown = ref()

const hide = () => (dropdown.value?.hide())

function onClick(locale: PreferredLanguageEnum) {
  emit('change', locale)
  hide()
}
</script>

<template>
  <Dropdown id="dropdown-language" ref="dropdown" :title="$t('Languages')">
    <template #default>
      <button
        class="btn btn-light-blue custom-dropdown-toggle ms-lg-2 mt-lg-0 mt-2 h-100"
        :class="{ 'bg-white ms-0': freeQuote }"
        type="button"
      >
        <span class="fi fis flag " :class="[`fi-${localeFlag($i18n.locale)}`]" style="scale: 1.25" />
      </button>
    </template>
    <template #content>
      <ul class="overflow-auto p-0 m-0">
        <li
          v-for="(locale, index) in $i18n.availableLocales" :key="`locale-${locale}`"
          class="dropdown-item cursor-pointer" :class="{ 'mt-2': index > 0 }"
          @click="onClick(locale as PreferredLanguageEnum)"
        >
          <span class="fi fis flag me-2" :class="[`fi-${localeFlag(locale)}`]" />
          <span>{{ localeTitle(locale) }}</span>
        </li>
      </ul>
    </template>
  </Dropdown>
</template>

<style scoped lang="scss">
@import '@styles/abstracts/colors';
.btn-light-blue {
  background-color: $primary-light;
}
.custom-dropdown-toggle:hover {
  background-color: $primary-light;
}
</style>
