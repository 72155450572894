<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'
import { Form } from 'vee-validate'
import { useToast } from 'vue-toastification'
import { object, string } from 'yup'
import { useGtm } from '@gtm-support/vue-gtm'
import { collectAndShowMessages } from '@/utils/address-validation'
import { useAddressValidator } from '@/composables/address-validation'
import { useAuthStore } from '@store/auth'
import { TypeEnum, VehicleTypeEnum } from '@apiTypes'
import type { CountryEnum, QuoteByID, SpecialRequirements, Waypoint } from '@apiTypes'
import type { Address } from '@/utils'
import { getUserTimeZone } from '@/utils'
import { useQuote } from '@/composables/quotes'

const props = defineProps({
  vehicleType: {
    type: String as PropType<VehicleTypeEnum>,
    default: VehicleTypeEnum.VAN,
  },
})

const { createQuote, loading, error } = useQuote()

const { currentUser } = storeToRefs(useAuthStore())
const vehicleType = toRef(props, 'vehicleType')

const quote = ref<Partial<QuoteByID>>({
  waypoints: [] as Waypoint[],
  special_requirements: {} as SpecialRequirements,
})

const router = useRouter()
const gtm = useGtm()
const form = ref()
const toast = useToast()
const { t } = useI18n()
const { validate, hasIssues } = useAddressValidator()

const validationSchema = object({
  pickup: string().required(t('Pickup place is required')),
  delivery: string().required(t('Delivery place is required')),
})

function updateAddress(type: TypeEnum.Pickup | TypeEnum.Delivery, address: Address, sequence: number) {
  if (!address.city || !address.country) {
    toast.warning(t('Please specify {type} which has at least city and country.', { type }))

    return
  }

  const waypoint: Partial<Waypoint> = {
    city: address.city,
    country: address.country as CountryEnum,
    postcode: address.postal_code,
    street: address.street,
    street_number: address.home,
    name: address.name,
    point: address.point,
    sequence,
    type,
  }

  if (!quote.value.waypoints)
    quote.value.waypoints = []

  const existingIndex = quote.value.waypoints?.findIndex(wp => wp.type === type)

  if (existingIndex > -1)
    quote.value.waypoints[existingIndex] = waypoint as Waypoint
  else
    quote.value.waypoints?.push(waypoint as Waypoint)
}

async function onSubmit() {
  const result = await validate(vehicleType, quote)

  if (hasIssues.value) {
    collectAndShowMessages(result.value, toast)
  }
  else {
    quote.value = {
      ...quote.value,
      vehicle_type: vehicleType.value,
    }
    if (currentUser.value) {
      quote.value.user = {
        ...currentUser.value,
        timezone: currentUser.value.timezone || getUserTimeZone(),
      }
    }

    await createQuote(quote.value)
    if (!error.value) {
      toast.clear()
      await router.push('/free-quote')
      gtm?.trackEvent({
        event: 'get_price',
        action: 'click',
      })
    }
  }
}

watch(error, value => value && toast.error(value))
</script>

<template>
  <Form
    ref="form" class="row gy-2 gx-3 my-2 align-items-center free-quote-form"
    :validation-schema="validationSchema"
    @submit="onSubmit"
  >
    <div class="col-sm pe-sm-3">
      <TextInput
        :value="quote.waypoints?.[0]?.city" name="pickup"
        :label="$t('From')"
        required
        use-google-maps-autocomplete
        :placeholder="$t('Enter pickup location')"
        css-class="form-control-lg w-100"
        @address-change="updateAddress(TypeEnum.Pickup, $event, 1)"
      />
    </div>
    <div class="col-sm px-sm-3">
      <TextInput
        :value="quote.waypoints?.[1]?.city" name="delivery"
        :label="$t('To')"
        required
        use-google-maps-autocomplete
        :placeholder="$t('Enter delivery location')"
        css-class="form-control-lg w-100"
        @address-change="updateAddress(TypeEnum.Delivery, $event, 2)"
      />
    </div>
    <div class="col-sm ps-sm-3">
      <LoadingButton
        class="btn btn-lg btn-primary w-100" type="submit"
        style="min-width: 50%"
        :loading="loading"
        :title="$t('Get your price')"
      />
    </div>
  </Form>
</template>

<style lang="scss">
.free-quote-form {
  .input-group {
    >div {
      flex-basis: 100%;
    }
  }
}
</style>
