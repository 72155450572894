<script setup lang="ts">
import { useToast } from 'vue-toastification'
import SignInButton from '@/views/auth/components/SignInButton.vue'
import LanguageSwitcher from '@/views/language/LanguageSwitcher.vue'
import { useLanguageService } from '@/composables/language'

const { t } = useI18n()
const toast = useToast()
const languageService = useLanguageService()

function handleLanguageChange(newLang: string) {
  languageService.setLanguage(newLang).catch(() => {
    toast.error(t('Could not set language. Try again later.'))
  })
}
</script>

<template>
  <nav id="header" class="d-flex justify-content-between mx-0 navbar navbar-expand-lg">
    <div class="align-content-center">
      <RouterLink to="/">
        <img src="@images/new_logo.svg" alt="Logo" class="d-inline-block navbar-brand">
      </RouterLink>
    </div>
    <button
      class="navbar-toggler" type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav" aria-controls="navbarNav"
      aria-expanded="false" aria-label="Toggle navigation"
    >
      <i class="fa-solid fa-bars py-1" />
    </button>
    <div id="navbarNav" class="collapse navbar-collapse flex-grow-0">
      <ul class="navbar-nav">
        <li class="nav-item d-flex gap-2 flex-column flex-md-row">
          <div class="bg-white text-muted rounded-3 px-3 py-1 d-flex align-items-center overflow-hidden">
            <img src="@images/ic_questionmark.svg" alt="questionmark icon">
            <div class="ms-2">
              <span>{{ $t('Need help?') }}</span><br>
              <span class="fw-bold">
                <a class="text-decoration-none" href="mailto:quote@instacarrier.eu">quote@instacarrier.eu</a>
              </span>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <LanguageSwitcher free-quote @change="handleLanguageChange" />
        </li>
        <li class="nav-item d-flex gap-2 flex-column flex-md-row">
          <SignInButton free-quote />
        </li>
      </ul>
    </div>
  </nav>
</template>

<style scoped lang="scss">
@import '@styles/abstracts/colors';

#header {
  background-color: $light-gray;
  padding: 1.2rem 7rem;
  @media (max-width: 767px) {
    padding: 1.2rem 1rem;
  }
}
</style>
