{
    "(VAT included)": "(MwSt. enthalten)",
    "(VAT not included)": "(MwSt. nicht enthalten)",
    "Custom pricing": "Individuelle Preisgestaltung",
    "Page not found": "Seite nicht gefunden",
    "This is not the page you were looking for.": "Dies ist nicht die von Ihnen gesuchte Seite.",
    "Back": "Zurück",
    "Latest news, guides and posts": "Neueste Nachrichten, Anleitungen und Beiträge",
    "Stay updated with the latest insights and tips from our transport and technology experts.": "Bleiben Sie mit den neuesten Einblicken und Tipps unserer Transport- und Technologieexperten auf dem Laufenden.",
    "Go to InstaCarrier Blog": "Zum InstaCarrier-Blog",
    "Contact us": "Kontaktieren Sie uns",
    "Please email us at the following email address listed below or by filling out the form.": "Bitte senden Sie uns eine E-Mail an die unten angegebene Adresse oder füllen Sie das Formular aus.",
    "Privacy policy": "Datenschutzerklärung",
    "Terms and Conditions": "Allgemeine Geschäftsbedingungen",
    "Powered by SWIDA Innovative © 2023 - instacarrier.eu": "Powered by SWIDA Innovative © 2023 - instacarrier.eu",
    "Need help?": "Brauchen Sie Hilfe?",
    "How it works": "So funktioniert es",
    "Services and benefits": "Dienstleistungen und Vorteile",
    "Blog": "Blog",
    "Get FREE Quote": "Kostenloses Angebot einholen",
    "It is simple. Choose what you need to transport, and SWIDA Innovative will take care of the rest.": "Es ist ganz einfach. Wählen Sie, was Sie transportieren möchten, und SWIDA Innovative kümmert sich um den Rest.",
    "Instant quote and order": "Sofortangebot und Bestellung",
    "Send a transportation request": "Senden Sie eine Transportanfrage",
    "Get the quote": "Angebot einholen",
    "Book transport": "Transport buchen",
    "Transport confirmed": "Transport bestätigt",
    "Just in 2 hours": "In nur 2 Stunden",
    "Enjoy delivery and online dashboard/tracking": "Genießen Sie die Lieferung und das Online-Dashboard/Tracking",
    "Truck\\'s licence plate info": "Kennzeicheninformationen des LKW",
    "Pickup confirmed": "Abholung bestätigt",
    "Delivery tracking": "Lieferverfolgung",
    "Delivery confirmed": "Lieferung bestätigt",
    "Payment": "Zahlung",
    "Get a FREE quote now": "Holen Sie jetzt ein kostenloses Angebot ein",
    "Get the instant transport price.": "Erhalten Sie den Sofortpreis für den Transport.",
    "Get your price in 3 minutes. Pay Later!": "Erhalten Sie Ihren Preis in 3 Minuten. Später zahlen!",
    "InstaCarrier is a service provided by SWIDA Innovative company. We provide more than 40 000 transports around Europe per year. See what is included in the service.": "InstaCarrier ist ein Service der Firma SWIDA Innovative. Wir führen jährlich über 40.000 Transporte in ganz Europa durch. Sehen Sie, was im Service enthalten ist.",
    "Get a free quote now": "Holen Sie jetzt ein kostenloses Angebot ein",
    "Custom transport request": "Individuelle Transportanfrage",
    "Create a quick transport request.": "Erstellen Sie eine schnelle Transportanfrage.",
    "Price quote": "Preisangebot",
    "We provide instant freight quotes without unnecessary waiting.": "Wir bieten sofortige Frachtangebote ohne unnötiges Warten.",
    "Online booking": "Online-Buchung",
    "Get the experience of booking freight shipments in the 21st century.": "Erleben Sie, wie man Frachtsendungen im 21. Jahrhundert bucht.",
    "Just 2 hours": "Nur 2 Stunden",
    "From order to pickup from your location.": "Von der Bestellung bis zur Abholung an Ihrem Standort.",
    "License plate provided": "Kennzeichen bereitgestellt",
    "Truck\\'s license plate is shared with you immediately after order confirmation.": "Das Kennzeichen des LKW wird Ihnen unmittelbar nach der Bestellbestätigung mitgeteilt.",
    "Pickup and delivery confirmation": "Abhol- und Lieferbestätigung",
    "Is provided online by the Detail Page and notifications.": "Wird online über die Detailseite und Benachrichtigungen angezeigt.",
    "Stay updated during the transport.": "Bleiben Sie während des Transports informiert.",
    "Pay later": "Später bezahlen",
    "For verified clients, payment by invoice is possible.": "Für verifizierte Kunden ist die Zahlung per Rechnung möglich.",
    "not-found": "nicht gefunden",
    "Your e-mail address has been confirmed, you can log in.": "Ihre E-Mail-Adresse wurde bestätigt, Sie können sich anmelden.",
    "Email was already verified or url is no longer valid.": "Die E-Mail wurde bereits bestätigt oder der Link ist nicht mehr gültig.",
    "Confirmation": "Bestätigung",
    "Explore this quick and ease way to get price offers for your transports": "Entdecken Sie diesen schnellen und einfachen Weg, Preisangebote für Ihre Transporte zu erhalten",
    "Confirming email": "E-Mail wird bestätigt",
    "Account settings": "Kontoeinstellungen",
    "We need additional information to proceed your request.": "Wir benötigen weitere Informationen, um Ihre Anfrage zu bearbeiten.",
    "General": "Allgemein",
    "Change password": "Passwort ändern",
    "Transport Request": "Transportanfrage",
    "Describe your transport": "Beschreiben Sie Ihren Transport",
    "The quote has been canceled, please create a new one.": "Das Angebot wurde storniert, bitte erstellen Sie ein neues.",
    "satisfied clients": "zufriedene Kunden",
    "transports on the road right now": "Transporte, die gerade unterwegs sind",
    "years on the market": "Jahre am Markt",
    "Login": "Anmelden",
    "Password is required": "Passwort ist erforderlich",
    "The password reset link has expired": "Der Link zum Zurücksetzen des Passworts ist abgelaufen",
    "Password reset": "Passwort zurücksetzen",
    "Password was successfully reset. Now you can login to the application.": "Das Passwort wurde erfolgreich zurückgesetzt. Jetzt können Sie sich anmelden.",
    "Navigate to login": "Zum Login wechseln",
    "New password": "Neues Passwort",
    "New password again": "Neues Passwort erneut",
    "Reset password": "Passwort zurücksetzen",
    "Create account": "Konto erstellen",
    "Thank you for the price request.": "Vielen Dank für Ihre Anfrage.",
    "This is a beta version of the InstaCarrier platform with a limited capacity for automated processing. However, we will be delighted to attend to you in person.": "Dies ist eine Beta-Version der InstaCarrier-Plattform mit begrenzter Kapazität für automatisierte Prozesse. Wir freuen uns darauf, Sie persönlich zu betreuen.",
    "Please choose your preferred method of contact:": "Bitte wählen Sie Ihre bevorzugte Kontaktmethode:",
    "Do you have an idea about how to improve our services?": "Haben Sie eine Idee, wie wir unsere Dienstleistungen verbessern können?",
    "Share with us your opinion at ": "Teilen Sie uns Ihre Meinung mit unter ",
    "Thank you.": "Danke.",
    "We will contact you ASAP": "Wir werden Sie so schnell wie möglich kontaktieren",
    "Name is required": "Name ist erforderlich",
    "Country is required": "Land ist erforderlich",
    "Your data has been updated successfully.": "Ihre Daten wurden erfolgreich aktualisiert.",
    "Could not update user. Please try again later.": "Benutzer konnte nicht aktualisiert werden. Bitte versuchen Sie es später erneut.",
    "E-MAIL": "E-MAIL",
    "PHONE": "TELEFON",
    "e.g. +4211111111": "z.B. +4211111111",
    "NAME": "NAME",
    "Save changes": "Änderungen speichern",
    "Old password is required": "Altes Passwort ist erforderlich",
    "New Password is required": "Neues Passwort ist erforderlich",
    "Password changed successfully.": "Passwort wurde erfolgreich geändert.",
    "Could not change password. Please try again later.": "Passwort konnte nicht geändert werden. Bitte versuchen Sie es später erneut.",
    "OLD PASSWORD": "ALTES PASSWORT",
    "NEW PASSWORD": "NEUES PASSWORT",
    "NEW PASSWORD AGAIN": "NEUES PASSWORT erneut",
    "Update password": "Passwort aktualisieren",
    "Already customer?": "Bereits Kunde?",
    "Sign in": "Anmelden",
    "Login and get price": "Anmelden und Preis erhalten",
    "Email is required": "E-Mail ist erforderlich",
    "Password recovery": "Passwort wiederherstellen",
    "Email with the instructions was sent to the address provided.": "Eine E-Mail mit den Anweisungen wurde an die angegebene Adresse gesendet.",
    "Follow the instructions and get back to the application.": "Befolgen Sie die Anweisungen und kehren Sie zur Anwendung zurück.",
    "Close": "Schließen",
    "Create account and get price": "Konto erstellen und Preis erhalten",
    "Continue without an account": "Ohne Konto fortfahren",
    "User Menu": "Benutzermenü",
    "Sign Out": "Abmelden",
    "Sign In": "Anmelden",
    "Why to register?": "Warum registrieren?",
    "Get instant quotes": "Sofortangebote erhalten",
    "Submit your pickup location and receive a price in just 3 minutes without e-mail verification.": "Geben Sie Ihren Abholort an und erhalten Sie in nur 3 Minuten einen Preis, ohne E-Mail-Verifizierung.",
    "Track your shipments": "Verfolgen Sie Ihre Sendungen",
    "Easily access your shipping history and stay updated on your deliveries.": "Greifen Sie einfach auf Ihre Versandhistorie zu und bleiben Sie über Ihre Lieferungen informiert.",
    "Unlock exclusive rewards": "Exklusive Vorteile freischalten",
    "Be the first to know about special offers and promotions tailored for our valued customers.": "Seien Sie der Erste, der von speziellen Angeboten und Aktionen für unsere geschätzten Kunden erfährt.",
    "Is the company you are ordering for a VAT payer in your country?": "Ist das Unternehmen, für das Sie bestellen, in Ihrem Land umsatzsteuerpflichtig?",
    "COMPANY NAME": "FIRMENNAME",
    "Country": "Land",
    "VAT ID": "USt-ID",
    "Street": "Straße",
    "Street number": "Hausnummer",
    "City": "Stadt",
    "ZIP Code": "PLZ",
    "Verification email sent": "Bestätigungs-E-Mail gesendet",
    "Failed to resend verification email.": "Erneutes Senden der Bestätigungs-E-Mail fehlgeschlagen",
    "Could not login user. Please try again later.": "Anmeldung fehlgeschlagen. Bitte versuchen Sie es später erneut.",
    "Don’t have an account?": "Kein Konto?",
    "Create new account": "Neues Konto erstellen",
    "Resend verification email": "Bestätigungs-E-Mail erneut senden",
    "PASSWORD": "PASSWORT",
    "e.g. John Smith": "z.B. John Smith",
    "I agree with <a>Terms and conditions</a>": "Ich stimme den <a>Allgemeinen Geschäftsbedingungen</a> zu",
    "You must agree with Terms and conditions": "Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen",
    "Confirmation e-mail was sent to your e-mail address.": "Eine Bestätigungs-E-Mail wurde an Ihre E-Mail-Adresse gesendet.",
    "Could not register user. Please try again later.": "Registrierung fehlgeschlagen. Bitte versuchen Sie es später erneut.",
    "Already registered? ": "Bereits registriert? ",
    "PASSWORD AGAIN": "PASSWORT erneut",
    "VAT": "MwSt.",
    "Are you shopping as an individual or a company?": "Kaufen Sie als Privatperson oder als Unternehmen?",
    "Company": "Unternehmen",
    "Individual": "Privatperson",
    "Checking limit": "Prüflimit",
    "Retry": "Erneut versuchen",
    "The entered VAT ID was not found. Please enter company data manually.": "Die eingegebene USt-ID wurde nicht gefunden. Bitte geben Sie die Firmendaten manuell ein.",
    "Continue": "Fortfahren",
    "Autofill company details": "Firmendetails automatisch ausfüllen",
    "Enter your billing information": "Geben Sie Ihre Rechnungsinformationen ein",
    "Vat number": "USt.-Nummer",
    "Company name": "Firmenname",
    "Address": "Adresse",
    "ZIP": "PLZ",
    "Billing information": "Rechnungsinformationen",
    "Payment method": "Zahlungsmethode",
    "Please specify pick-up and delivery addresses.": "Bitte geben Sie Abhol- und Lieferadressen an.",
    "Failed to update waypoints": "Aktualisierung der Wegpunkte fehlgeschlagen",
    "Could not initialize payment": "Zahlung konnte nicht initialisiert werden",
    "Could not confirm order": "Bestellung konnte nicht bestätigt werden",
    "Could not save information": "Informationen konnten nicht gespeichert werden",
    "Could not save payment": "Zahlung konnte nicht gespeichert werden",
    "Phone is required": "Telefon ist erforderlich",
    "To ensure our dispatch team can reach you when necessary, please provide your phone number. Your understanding and cooperation are greatly appreciated.": "Damit unser Disponent Sie bei Bedarf erreichen kann, geben Sie bitte Ihre Telefonnummer an. Wir danken für Ihr Verständnis und Ihre Kooperation.",
    "Phone number": "Telefonnummer",
    "Order confirmation and payment": "Bestellbestätigung und Zahlung",
    "Order confirmation": "Bestellbestätigung",
    "Discount Code": "Rabattcode",
    "Apply": "Anwenden",
    "Price is above insurance limit": "Preis liegt über dem Versicherungslimit",
    "Select payment method": "Wählen Sie die Zahlungsmethode",
    "Please choose and confirm payment method you prefer": "Bitte wählen und bestätigen Sie Ihre bevorzugte Zahlungsmethode",
    "Product selection": "Produktauswahl",
    "We offer you a choice of four products for your transport based on the time of loading and unloading. Choose which one best meets your speed and price requirements.": "Wir bieten Ihnen eine Auswahl von vier Produkten für Ihren Transport, basierend auf der Lade- und Entladezeit. Wählen Sie dasjenige, das am besten zu Ihren Anforderungen an Geschwindigkeit und Preis passt.",
    "Transit time:": "Transportzeit:",
    "Regular vehicle": "Reguläres Fahrzeug",
    "position check": "Positionsüberprüfung",
    "Verified carriers": "Zertifizierte Transportunternehmen",
    "by SWIDA": "von SWIDA",
    "Order now": "Jetzt bestellen",
    "Custom time": "Individuelle Zeit",
    "Pickup until": "Abholung bis",
    "Delivery until": "Lieferung bis",
    "Delivery": "Lieferung",
    "Pickup between": "Abholung zwischen",
    "Can not be empty": "Darf nicht leer sein",
    "Downtime duration is required if option is selected": "Stillstandszeit ist erforderlich, wenn die Option ausgewählt ist",
    "Duration have to be more than 0": "Dauer muss mehr als 0 betragen",
    "City is required": "Stadt ist erforderlich",
    "Company or name": "Firma oder Name",
    "Number": "Nummer",
    "Get your price in minutes": "Erhalten Sie Ihren Preis in Minuten",
    "Pickup in 2 hours": "Abholung in 2 Stunden",
    "Get express road shipment around Europe with a full control of your shipment with live shipment dashboard.": "Erhalten Sie Expressstraßentransporte in ganz Europa mit vollständiger Kontrolle über Ihre Sendung mittels Live-Tracking-Dashboard.",
    "Over 40,000 items delivered in last year": "Über 40.000 Sendungen im letzten Jahr geliefert",
    "Remove": "Entfernen",
    "COUNT OF UNITS": "Anzahl der Einheiten",
    "Type of Packaging": "Verpackungsart",
    "Unit length": "Einheitenlänge",
    "Unit Width": "Einheitenbreite",
    "Unit height": "Einheitenhöhe",
    "Unit Weight": "Einheitengewicht",
    "Stackable packaging": "Stapelfähige Verpackung",
    "Total weight": "Gesamtgewicht",
    "+ Add another cargo": "+ Weitere Ladung hinzufügen",
    "Special requirements": "Sonderanforderungen",
    "Temperature-controlled vehicle ": "Temperaturkontrolliertes Fahrzeug",
    "Frigo": "Kühlfahrzeug",
    "Device to load and unload heavy items": "Gerät zum Be- und Entladen schwerer Gegenstände",
    "TailLift": "Hecklift",
    "Transportation of dangerous goods based on UN conditions": "Transport gefährlicher Güter gemäß UN-Bestimmungen",
    "ADR": "ADR",
    "Driver actively assists in the loading of goods": "Fahrer unterstützt aktiv beim Laden",
    "Assisted loading ": "Unterstütztes Laden",
    "Driver actively assists in the unloading of goods": "Fahrer unterstützt aktiv beim Entladen",
    "Assisted unloading": "Unterstütztes Entladen",
    "Downtime of the vehicle due to longer loading or unloading": "Fahrzeugstillstand aufgrund längerer Lade- oder Entladezeiten",
    "Vehicle downtime": "Fahrzeugstillstand",
    "hour": "Stunde",
    "Loading area length up to 4.8 meters": "Ladeflächenlänge bis zu 4,8 Metern",
    "Extended loading area": "Erweiterte Ladefläche",
    "km": "km",
    "Coming soon": "Demnächst verfügbar",
    "Price starting from": "Preis ab",
    "Your name and surname": "Ihr Vor- und Nachname",
    "Customer name and surname": "Vor- und Nachname des Kunden",
    "Work e-mail": "Geschäftliche E-Mail",
    "Customer e-mail": "Kunden-E-Mail",
    "To see final price and shipping option please add cargo details first. The final price depends on the size and weight of the goods as well as the required loading time": "Um den Endpreis und die Versandoption zu sehen, fügen Sie bitte zuerst die Frachtinformationen hinzu. Der Endpreis hängt von Größe, Gewicht und der benötigten Ladezeit ab.",
    "Your message has been submitted. Thank you!": "Ihre Nachricht wurde übermittelt. Vielen Dank!",
    "YOUR NAME": "IHR NAME",
    "YOUR WORK E-MAIL": "IHRE GESCHÄFTLICHE E-MAIL",
    "PHONE NUMBER (OPTIONAL)": "TELEFONNUMMER (OPTIONAL)",
    "MESSAGE": "NACHRICHT",
    "Pickup place is required": "Abholort ist erforderlich",
    "Delivery place is required": "Lieferort ist erforderlich",
    "Please specify {type} which has at least city and country.": "Bitte geben Sie {type} an, die mindestens Stadt und Land enthält.",
    "From": "Von",
    "Enter pickup location": "Geben Sie den Abholort ein",
    "To": "Nach",
    "Enter delivery location": "Geben Sie den Lieferort ein",
    "Get your price": "Erhalten Sie Ihren Preis",
    "The selected datetime cannot be in the past": "Das ausgewählte Datum und die Uhrzeit dürfen nicht in der Vergangenheit liegen",
    "The selected datetime must be within the next 30 days": "Das ausgewählte Datum und die Uhrzeit müssen innerhalb der nächsten 30 Tage liegen",
    "Date and time is required": "Datum und Uhrzeit sind erforderlich",
    "Invalid date and time": "Ungültiges Datum und Uhrzeit",
    "Choose one": "Wählen Sie eine Option",
    "Count is required": "Anzahl ist erforderlich",
    "Count should be greater than or equal to 1": "Anzahl muss größer oder gleich 1 sein",
    "Count should not be greater than 250": "Anzahl darf 250 nicht überschreiten",
    "Length is required": "Länge ist erforderlich",
    "Length should be greater than or equal to 1": "Länge muss größer oder gleich 1 sein",
    "Width is required": "Breite ist erforderlich",
    "Width should be greater than or equal to 1": "Breite muss größer oder gleich 1 sein",
    "Height is required": "Höhe ist erforderlich",
    "Height should be greater than or equal to 1": "Höhe muss größer oder gleich 1 sein",
    "Unit weight is required": "Einheitengewicht ist erforderlich",
    "Unit weight should be greater than or equal to 1": "Einheitengewicht muss größer oder gleich 1 sein",
    "You must agree to the terms and conditions": "Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen",
    "Ordering is only possible for company customers. Please use a company email account.": "Bestellungen sind nur für Firmenkunden möglich. Bitte verwenden Sie eine Firmen-E-Mail-Adresse.",
    "PICKUP": "ABHOLUNG",
    "LATEST PICKUP": "NEUESTE ABHOLUNG",
    "DELIVERY": "LIEFERUNG",
    "LATEST DELIVERY": "NEUESTE LIEFERUNG",
    "Transport Duration": "Transportdauer",
    "hours": "Stunden",
    "Estimated Distance": "Geschätzte Entfernung",
    "Freight details": "Frachtinformationen",
    "Define your cargo details below, you can select from various packaging and vehicle types.": "Definieren Sie Ihre Frachtinformationen unten; Sie können aus verschiedenen Verpackungs- und Fahrzeugtypen wählen.",
    "Additional notes": "Zusätzliche Hinweise",
    "Anything else you want to add?": "Möchten Sie noch etwas hinzufügen?",
    "Enter your notes here...": "Geben Sie hier Ihre Hinweise ein...",
    "Your price quote is on its way.": "Ihr Preisangebot ist unterwegs.",
    "We are very sorry the InstaCarrier platform did not fulfill your expectations, yet. Let us work on it for a few more weeks and inform you about its full glorious launch to the world.": "Es tut uns leid, dass die InstaCarrier-Plattform noch nicht Ihren Erwartungen entspricht. Lassen Sie uns noch ein paar Wochen daran arbeiten und wir informieren Sie über den endgültigen, glorreichen Launch.",
    "We have already started to work on your request. You can expect a price quote in your inbox in the next 30 minutes.": "Wir haben bereits mit der Bearbeitung Ihrer Anfrage begonnen. Sie erhalten Ihr Preisangebot in den nächsten 30 Minuten in Ihrem Posteingang.",
    "Check your inbox, you can expect a price quote from InstaCarrier in a few moments.": "Überprüfen Sie Ihren Posteingang, in wenigen Augenblicken erhalten Sie ein Preisangebot von InstaCarrier.",
    "Your price quote is on its way to ": "Ihr Preisangebot ist unterwegs an ",
    "Having any questions?": "Haben Sie Fragen?",
    "Do you have an idea about how to improve our services? Share it with us at ": "Haben Sie eine Idee, wie wir unsere Dienstleistungen verbessern können? Teilen Sie sie uns mit unter ",
    "You have to choose one.": "Sie müssen eine Option wählen.",
    "Invalid contact method": "Ungültige Kontaktmethode",
    "Vehicle": "Fahrzeug",
    "Plate number": "Kennzeichen",
    "Your dispatcher": "Ihr Disponent",
    "Order confirmed": "Bestellung bestätigt",
    "Order summary": "Bestellübersicht",
    "Your order has been sent for processing. An assigned operator will contact you shortly.": "Ihre Bestellung wurde zur Bearbeitung versandt. Ein zuständiger Mitarbeiter wird sich in Kürze mit Ihnen in Verbindung setzen.",
    "Check the order you entered and confirm its correctness with the button at the bottom.": "Überprüfen Sie Ihre Bestellung und bestätigen Sie deren Richtigkeit mit dem Button unten.",
    "Route details": "Routeninformationen",
    "Cargo information": "Frachtinformationen",
    "No payment method selected": "Keine Zahlungsmethode ausgewählt",
    "Your price with VAT": "Ihr Preis inkl. MwSt.",
    "Your price without VAT": "Ihr Preis ohne MwSt.",
    "discount applied": "Rabatt angewendet",
    "driver assisted loading": "Fahrerunterstütztes Laden",
    "driver assisted unloading": "Fahrerunterstütztes Entladen",
    "vehicle downtime": "Fahrzeugstillstand",
    "Service and price": "Service und Preis",
    "Distance": "Entfernung",
    "Duration": "Dauer",
    "URL copied successfully": "URL erfolgreich kopiert",
    "Transportation order": "Transportauftrag",
    "Finish your transportation order.": "Schließen Sie Ihren Transportauftrag ab.",
    "Share a price quote": "Teilen Sie ein Preisangebot",
    "Offer expires in": "Angebot verfällt in",
    "placeholder_email_example": "",
    "Company is VAT registered": "Das Unternehmen ist umsatzsteuerpflichtig",
    "You need to specify { pickup } which has at least city and country.": "Sie müssen { pickup } angeben, das mindestens Stadt und Land enthält.",
    "You need to specify { delivery } which has at least city and country.": "Sie müssen { delivery } angeben, das mindestens Stadt und Land enthält.",
    "Click to View Interactive Map": "Klicken Sie, um die interaktive Karte anzuzeigen",
    "Powered by SWIDA Innovative © 2024 - instacarrier.eu": "Powered by SWIDA Innovative © 2024 - instacarrier.eu",
    "Pickup date is invalid": "Das Abholdatum ist ungültig",
    "Pickup date is required": "Abholdatum ist erforderlich",
    "Pickup time must be between {start}:00 and {end}:00": "Die Abholzeit muss zwischen {start}:00 und {end}:00 liegen",
    "Delivery date is invalid": "Das Lieferdatum ist ungültig",
    "Delivery date is required": "Lieferdatum ist erforderlich",
    "Delivery time must be between {start}:00 and {end}:00": "Die Lieferzeit muss zwischen {start}:00 und {end}:00 liegen",
    "No updates available": "Keine Updates verfügbar",
    "Goods unloaded": "Waren entladen",
    "Arrived for loading": "Zum Be- und Entladen angekommen",
    "Arrived for unloading": "Zum Entladen angekommen",
    "Goods loaded": "Waren verladen",
    "Unknown status": "Unbekannter Status",
    "You will see location updates soon": "Bald erhalten Sie Standortaktualisierungen",
    "Searching for a vehicle...": "Suche nach einem Fahrzeug...",
    "Transport order": "Transportauftrag",
    "Transport detail": "Transportdetails",
    "Transport status:": "Transportstatus:",
    "Transit history": "Transportverlauf",
    "Product option": "Produktoption",
    "Selected product option": "Ausgewählte Produktoption",
    "Description": "Beschreibung",
    "Track your order here!": "Verfolgen Sie Ihre Bestellung hier!",
    "Applied discounts": "Angewendete Rabatte",
    "Vehicle Type": "Fahrzeugtyp",
    "You need to specify { type } which has at least city and country.": "Sie müssen { type } angeben, das mindestens Stadt und Land enthält.",
    "Looks like you would like to { loadType } goods in a country or region our system is not able to quote automatically. Don't worry, we'll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.": "Es sieht so aus, als möchten Sie Waren in einem Land oder einer Region transportieren, für die unser System keine automatische Preisermittlung durchführen kann. Keine Sorge, wir helfen Ihnen gerne weiter! Bitte senden Sie uns eine E-Mail mit Ihrer Anfrage, und wir melden uns so schnell wie möglich bei Ihnen.",
    "poweredBy": "poweredBy",
    "The weight of your packages specified in your transportation request exceeds the limit of the vehicle equipped with tail lift, but don\\'t worry, we\\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.": "Das Gewicht Ihrer in der Transportanfrage angegebenen Pakete überschreitet das Limit des Fahrzeugs mit Hecklift. Aber keine Sorge, wir helfen Ihnen weiter! Wählen Sie einen größeren Fahrzeugtyp oder senden Sie uns eine E-Mail mit Ihrer Anfrage, und wir melden uns so schnell wie möglich bei Ihnen.",
    "Loading area length up to 7.5 meters": "Ladeflächenlänge bis zu 7,5 Metern",
    "Loading area height up to 3 meters": "Ladeflächenhöhe bis zu 3 Metern",
    "Downtime of the vehicle for more than 2 hours due to longer loading or unloading": "Fahrzeugstillstand von mehr als 2 Stunden aufgrund längerer Be- oder Entladezeiten",
    "NEW": "NEU",
    "Plate number trailer": "Kennzeichen des Anhängers",
    "Order Manager": "Auftragsmanager",
    "We are": "Wir sind",
    " currently searching ": " suchen gerade ",
    "for the perfect vehicle": "nach dem perfekten Fahrzeug",
    "View offers in the next step.": "Angebote im nächsten Schritt anzeigen.",
    "Pickup datetime is required": "Abholdatum und -zeit sind erforderlich",
    "Datetime": "Datum und Uhrzeit"
}
