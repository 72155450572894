import type { ComputedRef, Ref } from 'vue'
import { ref } from 'vue'
import { useQuoteStore } from '@/stores/quote'
import type { QuoteByHash, QuoteByID, UpdateUserBillingType, Waypoint } from '@apiTypes'

export function useQuote(): {
  quoteByHash: ComputedRef<QuoteByHash>
  loading: Ref<boolean>
  error: Ref<string>
  quoteByID: ComputedRef<QuoteByID>
  updateQuote: (payload: Partial<QuoteByID>) => Promise<void>
  updateQuoteByHash: (payload: Partial<QuoteByHash>) => Promise<void>
  createQuote: (payload: Partial<QuoteByID>) => Promise<void>
  updateWaypoint: (updatedWaypoint: Waypoint, index: number, toast: any) => void
  updateQuoteUserType: (hash: string | undefined, payload: Partial<UpdateUserBillingType>) => Promise<void> } {
  const loading = ref(false)
  const error = ref('')
  const quoteStore = useQuoteStore()
  const quoteByID = computed(() => quoteStore.quoteByID)
  const quoteByHash = computed(() => quoteStore.quoteByHash)

  const createQuote = async (payload: Partial<QuoteByID>) => {
    loading.value = true
    try {
      await quoteStore.createQuote(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  const updateQuote = async (payload: Partial<QuoteByID>) => {
    loading.value = true
    try {
      // TODO: cleanup
      const specialRequirementsID = quoteByID.value.special_requirements?.id
      if (specialRequirementsID && payload.special_requirements)
        payload.special_requirements.id = specialRequirementsID

      await quoteStore.updateQuote(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  const updateQuoteByHash = async (payload: Partial<QuoteByHash>) => {
    loading.value = true
    try {
      await quoteStore.updateQuoteByHash(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  const updateWaypoint = async (updatedWaypoint: Waypoint, index: number, toast: any) => {
    loading.value = true
    try {
      await quoteStore.updateWaypoint(updatedWaypoint, index)
      error.value = ''
      await quoteStore.specialRequirementPrices()
    }
    catch (e: any) {
      const status = e.response.status
      const data = e.response.data
      if ((status === 404 || status === 400) && data.error)
        toast.error(data.error)
    }
    finally { loading.value = false }
  }

  const updateQuoteUserType = async (hash: string | undefined, payload: Partial<UpdateUserBillingType>) => {
    loading.value = true
    try {
      if (hash)
        await quoteStore.updateQuoteUserType(hash, payload)

      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  return { loading, error, createQuote, updateQuote, updateQuoteByHash, quoteByID, quoteByHash, updateWaypoint, updateQuoteUserType }
}
