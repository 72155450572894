import { acceptHMRUpdate, defineStore } from 'pinia'
import { supportedLanguages } from '@/plugins/i18n'
import api from '@api'
import type {
  PasswordChange,
  PasswordReset,
  PasswordResetConfirm, PreferredLanguageEnum,
  ResendEmailVerification,
  TokenVerify,
  UserDetails,
  VerifyEmail,
} from '@apiTypes'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isNewRegistration: false,
    currentUser: null as UserDetails | null,
  }),
  getters: {
    getCurrentLanguage: state => {
      return state.currentUser?.preferred_language || supportedLanguages[0]
    },
  },
  actions: {
    clear() {
      this.currentUser = null
    },
    setCurrentUser(user: UserDetails) {
      this.currentUser = user
    },
    setPassword(data: PasswordChange) {
      return api.password.authPasswordChangeCreate(data)
    },
    resetPassword(data: PasswordReset) {
      return api.password.authPasswordResetCreate(data, { skipAuthToken: true })
    },
    verifyToken(data: TokenVerify) {
      return api.token.authTokenVerifyCreate(data, { skipAuthToken: true, skipAuthRefresh: true, skipLogout: true })
    },
    confirmResetPassword(data: PasswordResetConfirm) {
      return api.password.authPasswordResetConfirmCreate(data, { skipAuthToken: true })
    },
    confirmEmail(data: VerifyEmail) {
      return api.register.authRegisterVerifyEmailCreate(data, { skipAuthToken: true })
    },
    resendConfirmationEmail(data: ResendEmailVerification) {
      return api.register.authRegisterResendEmailCreate(data)
    },
    checkEmailVerify(email: string) {
      return api.checkEmailVerification.authCheckEmailVerificationRetrieve({ email })
    },
    async fetchCurrentUser() {
      return api.user.authUserRetrieve({ skipLogout: true, skipAuthRefresh: true }).then(response => {
        this.setCurrentUser(response.data)

        return response
      })
    },
    async updateUser(data: UserDetails) {
      return api.user.authUserUpdate(data).then(response => {
        this.setCurrentUser(response.data)

        return response
      })
    },
    async updateLanguage(preferred_language: PreferredLanguageEnum) {
      return api.user.authUserPartialUpdate({ preferred_language }).then(response => {
        this.setCurrentUser(response.data)

        return response
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
