import type { ToastInterface } from 'vue-toastification'
import { Countries } from '@/utils/countries'
import type { Waypoint } from '@apiTypes'

export interface ValidationResult {
  errors: string[]
  warnings: string[]
}

export interface AddressCheckResult {
  pickup: ValidationResult
  delivery: ValidationResult
  global: ValidationResult
}

export function areAddressesUnique(...addresses: Waypoint[]) {
  if (!addresses)
    return false

  const addressesCoordinates = []
  for (const address of addresses.values())
    addressesCoordinates.push(address.point?.coordinates?.toString())

  return new Set(addressesCoordinates).size === addresses.length
}

export function isDisallowedCountry(address: Waypoint) {
  if (!Countries.includes(address.country))
    return true
}

export function collectAndShowMessages(addressCheckResult: AddressCheckResult, toast: ToastInterface) {
  Object.values(addressCheckResult).forEach(entry => {
    entry.errors.forEach(error => toast.error(error))
    entry.warnings.forEach(warning => toast.warning(warning))
  })
}
