import { computed, ref, watch } from 'vue'
import type { PreferredLanguageEnum, UserDetails } from '@apiTypes'
import i18n, { isLanguageSupported, supportedLanguages } from '@/plugins/i18n'
import { useAuthStore } from '@/stores/auth'

const _currentLanguage = ref<string>(localStorage.getItem('user-language') || supportedLanguages[0])
const _initialized = ref<boolean>(false)

function getPreferredLanguage(user: UserDetails | null): string {
  if (user && isLanguageSupported(user.preferred_language))
    return user.preferred_language as string

  const storedLang = localStorage.getItem('user-language')
  if (storedLang && isLanguageSupported(storedLang))
    return storedLang

  return supportedLanguages[0]
}

function isLanguageStateConsistent(currentUser: UserDetails | null, currentLang: string): boolean {
  if (currentUser)
    return currentUser.preferred_language === currentLang

  return currentLang === localStorage.getItem('user-language')
}

export function useLanguageService() {
  const authStore = useAuthStore()

  const determinedLanguage = computed<string>(() =>
    getPreferredLanguage(authStore.currentUser),
  )

  const currentLanguage = computed<string>(() => _currentLanguage.value)

  function syncI18nLocale(lang: string): void {
    if (i18n.global.locale.value !== lang)
      i18n.global.locale.value = lang
  }

  async function initializeLanguage(): Promise<void> {
    if (_initialized.value && isLanguageStateConsistent(authStore.currentUser, _currentLanguage.value))
      return

    const lang = determinedLanguage.value

    if (lang !== _currentLanguage.value || i18n.global.locale.value !== lang)
      await setLanguage(lang)

    _initialized.value = true
  }

  async function setLanguage(lang: PreferredLanguageEnum): Promise<void> {
    if (!isLanguageSupported(lang))
      throw new Error(`Unsupported language: ${lang}`)

    syncI18nLocale(lang)

    _currentLanguage.value = lang
    localStorage.setItem('user-language', lang)

    if (authStore.currentUser)
      await authStore.updateLanguage(lang)
  }

  // Ensure i18n is synchronized with internal state
  syncI18nLocale(_currentLanguage.value)

  watch(determinedLanguage, async newLang => {
    if (_initialized.value && newLang !== _currentLanguage.value)
      await setLanguage(newLang)
  })

  return {
    currentLanguage,
    initializeLanguage,
    setLanguage,
  }
}
