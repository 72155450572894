<script lang="ts" setup>
import { useToast } from 'vue-toastification'
import SignInButton from '@/views/auth/components/SignInButton.vue'
import LanguageSwitcher from '@/views/language/LanguageSwitcher.vue'
import { useLanguageService } from '@/composables/language'

const emit = defineEmits<{
  (e: 'highlight'): void
}>()

const { t } = useI18n()
const toast = useToast()
const languageService = useLanguageService()

function handleLanguageChange(newLang: string) {
  languageService.setLanguage(newLang).catch(() => {
    toast.error(t('Could not set language. Try again later.'))
  })
}
</script>

<template>
  <nav class="navbar navbar-expand-lg secondary p-3">
    <div class="container">
      <a class="navbar-brand fst-italic fw-bold" href="/#">
        <img src="@images/new_logo_light.svg" alt="Logo" class="d-inline-block">
      </a>
      <button
        class="navbar-toggler" type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav" aria-controls="navbarNav"
        aria-expanded="false" aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div id="navbarNav" class="collapse navbar-collapse flex-grow-0">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link text-light" aria-current="page" href="/#how-it-works">
              {{ $t('How it works') }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-light" href="/#services">{{ $t('Services and benefits') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-light" href="/#contact">{{ $t('Contact us') }}</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-light"
              href="https://blog.instacarrier.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('Blog') }}
            </a>
          </li>
          <li class="nav-item">
            <button class="btn btn-primary h-100" @click="emit('highlight')">
              {{ $t('Get FREE Quote') }}
            </button>
          </li>
          <li class="nav-item">
            <LanguageSwitcher @change="handleLanguageChange" />
          </li>
          <li class="nav-item">
            <SignInButton />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
#navbarNav {
  .navbar-nav {
    .nav-item {
      .nav-link {
        padding: .5rem 2.25rem;
      }

      .btn {
        margin-left: 1.75rem;
      }
    }
  }
}
</style>
