import { VehicleTypeEnum } from '@apiTypes'
import type { BlankEnum } from '@apiTypes'
import bigLorryDefaultImage from '@images/ic_big_lorry_default.svg'
import bigLorryMutedImage from '@images/ic_big_lorry_muted.svg'
import bigLorrySelectedImage from '@images/ic_big_lorry_selected.svg'
import lkwDefaultImage from '@images/ic_lkw_default.svg'
import lkwMutedImage from '@images/ic_lkw_muted.svg'
import lkwSelectedImage from '@images/ic_lkw_selected.svg'
import lorryDefaultImage from '@images/ic_lorry_default.svg'
import lorryMutedImage from '@images/ic_lorry_muted.svg'
import lorrySelectedImage from '@images/ic_lorry_selected.svg'
import vanDefaultImage from '@images/ic_van_default.svg'
import vanMutedImage from '@images/ic_van_muted.svg'
import vanSelectedImage from '@images/ic_van_selected.svg'

export interface Vehicle {
  icon_default: string
  icon_muted: string
  icon_selected: string
  type: VehicleTypeEnum
  title: string
  description: string
  dimensions?: VehicleDimensions
  weight: number
  ltlWeight: number
  ltlPartialWeight: number
  tailLiftWeight: number
  ltlTailLiftWeight: number
  ltlSpacePercentage: number
  selected: boolean
  enabled?: boolean
  isNew: boolean
}

export interface VehicleDimensions {
  width: number // in cm
  length: number // in cm
  height: number // in cm
}

export const vehicles: Vehicle[] = [
  {
    type: VehicleTypeEnum.VAN,
    title: 'VAN',
    description: 'up to 1 ton',
    icon_default: vanDefaultImage,
    icon_selected: vanSelectedImage,
    icon_muted: vanMutedImage,
    dimensions: { width: 200, length: 420, height: 200 },
    weight: 1000,
    ltlWeight: 500,
    ltlPartialWeight: 350,
    tailLiftWeight: 750,
    ltlTailLiftWeight: 250,
    ltlSpacePercentage: 50,
    enabled: true,
    selected: true,
    isNew: false,
  },
  {
    type: VehicleTypeEnum.SMALL_LORRY,
    title: 'Lorry',
    description: 'up to 2,8 tons',
    icon_default: lorryDefaultImage,
    icon_selected: lorrySelectedImage,
    icon_muted: lorryMutedImage,
    dimensions: { width: 245, length: 600, height: 240 },
    weight: 2800,
    ltlWeight: -1,
    ltlPartialWeight: -1,
    tailLiftWeight: 2300,
    ltlTailLiftWeight: -1,
    ltlSpacePercentage: -1,
    enabled: true,
    selected: false,
    isNew: true,
  },
  {
    type: VehicleTypeEnum.BIG_LORRY,
    title: 'Big Lorry',
    description: 'up to 5 tons',
    icon_default: bigLorryDefaultImage,
    icon_selected: bigLorrySelectedImage,
    icon_muted: bigLorryMutedImage,
    dimensions: { width: 245, length: 700, height: 250 },
    weight: 5000,
    ltlWeight: -1,
    ltlPartialWeight: -1,
    tailLiftWeight: 4500,
    ltlTailLiftWeight: -1,
    ltlSpacePercentage: -1,
    enabled: true,
    selected: false,
    isNew: true,
  },
  {
    type: VehicleTypeEnum.LKW,
    title: 'LKW',
    description: 'up to 24 tons',
    icon_default: lkwDefaultImage,
    icon_selected: lkwSelectedImage,
    icon_muted: lkwMutedImage,
    dimensions: { width: 245, length: 1360, height: 270 },
    weight: 24000,
    ltlWeight: 8500,
    ltlPartialWeight: -1,
    tailLiftWeight: -1,
    ltlTailLiftWeight: -1,
    ltlSpacePercentage: 30,
    enabled: true,
    selected: false,
    isNew: true,
  },
]

export function getVehicleByType(vehicleType: VehicleTypeEnum | BlankEnum | undefined) {
  return vehicles.find(item => item.type === vehicleType) || vehicles[0]
}
