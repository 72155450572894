import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from '~pages'
import { extendRouteWithGuard } from '@/router/guards'
import { authMiddleware, languageMiddleware } from '@/router/middleware'

const routes = [
  { path: '/home', redirect: '/' },
  ...setupLayouts(generatedRoutes),
].map(route => extendRouteWithGuard(route))

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(languageMiddleware)
router.beforeEach(authMiddleware)

export default router
